// src/components/EventComponent.js
import React from 'react';

const EventComponent = ({ event }) => {
  return (
    <div>
      <strong>{event.title}</strong>
      <div>{event.location}</div>
      <div>{event.presenter}</div>
    </div>
  );
};

export default EventComponent;
