import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from '../firebaseConfig';
import { getDoc, doc, setDoc, collection } from 'firebase/firestore';
import styles from './QuizResultsPage.module.css';

const QuizResultsPage = ({ user }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { questions = [], answers = {}, lessonId, title, category } = location.state || {};
  const [correctAnswers, setCorrectAnswers] = useState({});

  useEffect(() => {
    const fetchCorrectAnswers = async () => {
      try {
        const correctAnswersData = {};
        for (let question of questions) {
          const questionDocRef = doc(firestore, 'lessons', question.lessonId, 'quiz', question.id);
          const questionDoc = await getDoc(questionDocRef);
          if (questionDoc.exists) {
            const data = questionDoc.data();
            correctAnswersData[question.id] = data.correctAnswerIndex; // Ensure this matches your database structure
          } else {
            console.error(`No such document for question ${question.id}`);
          }
        }
        setCorrectAnswers(correctAnswersData);
      } catch (error) {
        console.error('Error fetching correct answers:', error);
      }
    };

    fetchCorrectAnswers();
  }, [questions]);

  const isAnswerCorrect = (questionId, userAnswer) => {
    const correctAnswerIndex = correctAnswers[questionId];
    if (correctAnswerIndex === undefined) {
      return false;
    }
    const correctAnswer = questions.find(q => q.id === questionId).options[correctAnswerIndex];
    return userAnswer.includes(correctAnswer);
  };

  const renderOption = (questionId, option, index) => {
    const userAnswer = answers[questionId] || [];
    const correctAnswerIndex = correctAnswers[questionId];
    const isCorrectAnswer = Array.isArray(correctAnswerIndex) ? correctAnswerIndex.includes(index) : correctAnswerIndex === index;
    const isSelectedByUser = userAnswer.includes(option);

    const optionClass = isSelectedByUser
      ? `${styles.option} ${styles.selected}`
      : styles.option;

    return (
      <div key={option} className={optionClass}>
        {isCorrectAnswer && <span className={styles.correctMark}>✔</span>}
        {option}
      </div>
    );
  };

  const handleBackToMain = async () => {
    if (!user || !user.uid) {
      console.error('User UID is missing');
      return;
    }

    if (!lessonId) {
      console.error('Lesson ID is missing');
      return;
    }

    try {
      const completionDate = new Date().toISOString();
      const completedLesson = {
        lessonId,
        title,
        category,
        completionDate
      };

      const userDocRef = doc(firestore, 'users', user.uid);
      const completedLessonsCollectionRef = collection(userDocRef, 'completedLessons');
      const completedLessonDocRef = doc(completedLessonsCollectionRef, lessonId);

      await setDoc(completedLessonDocRef, completedLesson);
    } catch (error) {
      console.error('Error recording completed lesson:', error);
    }

    navigate('/');
  };

  return (
    <div className={styles.resultsContainer}>
      <div className={styles.resultsCard}>
        <h2>Quiz Results</h2>
        {questions.map((question) => (
          <div key={question.id} className={styles.resultItem}>
            <h3 className={styles.questionText}>{question.question}</h3>
            <div className={styles.optionsContainer}>
              {question.options.map((option, index) => renderOption(question.id, option, index))}
            </div>
            <p className={isAnswerCorrect(question.id, answers[question.id]) ? styles.correctText : styles.incorrectText}>
              {isAnswerCorrect(question.id, answers[question.id]) ? 'Correct' : `Incorrect. Correct answer: ${questions.find(q => q.id === question.id).options[correctAnswers[question.id]]}`}
            </p>
          </div>
        ))}
        <button className={styles.finishButton} onClick={handleBackToMain}>
          Finish Lesson
        </button>
      </div>
    </div>
  );
};

export default QuizResultsPage;
