import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import './SelectSchool.css';

const SelectSchool = ({ user }) => {
  const [school, setSchool] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();

  const handleContinue = async () => {
    if (school && role) {
      const assignedRole = role === 'Staff' ? 'StaffTemp' : role; // Assign StaffTemp if Staff is selected
      await setDoc(doc(firestore, 'users', user.uid), { school, role: assignedRole }, { merge: true });
      navigate('/');
    } else {
      alert('Please select a school and role.');
    }
  };

  const schools = [
    'Aberlady Primary School',
    'Athelstaneford Primary School',
    'Campie Primary School',
    'Cockenzie Primary School',
    'Dirleton Primary School',
    'Dunbar Grammar School',
    'Dunbar Primary School',
    'East Linton Primary School',
    'Elphinstone Primary School',
    'Gullane Primary School',
    'Haddington Primary School',
    'Innerwick Primary School',
    'Knox Academy',
    'Law Primary School',
    'Letham Mains Primary School',
    'Longniddry Primary School',
    'Loretto Primary School',
    'Macmerry Primary School',
    'Musselburgh Burgh Primary School',
    'Musselburgh Grammar School',
    'North Berwick High School',
    'Ormiston Primary School',
    'Pencaitland Primary School',
    'Pinkie St Peter\'s Primary School',
    'Preston Lodge High School',
    'Preston Tower Primary School',
    'Rosehill High School',
    'Ross High School',
    'Saltoun Primary School',
    'Sanderson\'s Wynd Primary School',
    'St Gabriel\'s Primary School',
    'St Martin\'s Primary School',
    'St Mary\'s Primary School',
    'Stenton Primary School',
    'Stoneyhill Primary School',
    'Wallyford Primary School',
    'West Barns Primary School',
    'Whitecraig Primary School',
    'Windygoul Primary School',
    'Yester Primary School',
    'Central Education' // Added new school/location
  ];

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header bg-primary text-white">
          <h1>Select School and Role</h1>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="schoolSelect">Select School or Location</label>
            <select
              id="schoolSelect"
              className="form-control"
              value={school}
              onChange={(e) => setSchool(e.target.value)}
            >
              <option value="">Select School or Location</option>
              {schools.map((schoolName, index) => (
                <option key={index} value={schoolName}>
                  {schoolName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="roleSelect">Select Role</label>
            <select
              id="roleSelect"
              className="form-control"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">Select Role</option>
              <option value="Staff">Staff</option>
              <option value="Pupil">Pupil</option>
            </select>
          </div>
          <button className="btn btn-primary mt-3" onClick={handleContinue}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectSchool;
