import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc, addDoc, query, where } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import styles from './AdminDashboard.module.css';

const AdminDashboard = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [pendingLessons, setPendingLessons] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [newAnnouncement, setNewAnnouncement] = useState('');

  useEffect(() => {
    const fetchPendingUsers = async () => {
      try {
        const usersCollection = collection(firestore, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersData = usersSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => user.role === 'StaffTemp');
        setPendingUsers(usersData);
      } catch (error) {
        console.error('Error fetching pending users:', error);
      }
    };

    const fetchPendingLessons = async () => {
      try {
        const lessonsCollection = collection(firestore, 'lessons');
        const lessonsSnapshot = await getDocs(lessonsCollection);
        const lessonsData = lessonsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(lesson => lesson.status === 'pending');
        setPendingLessons(lessonsData);
      } catch (error) {
        console.error('Error fetching pending lessons:', error);
      }
    };

    const fetchAnnouncements = async () => {
      try {
        const announcementsCollection = collection(firestore, 'announcements');
        const announcementsSnapshot = await getDocs(announcementsCollection);
        const announcementsData = announcementsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAnnouncements(announcementsData);
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchPendingUsers();
    fetchPendingLessons();
    fetchAnnouncements();
    setLoading(false);
  }, []);

  const handleApproveUser = async (userId) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      await updateDoc(userDocRef, { role: 'Staff' });
      setPendingUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const handleRejectUser = async (userId) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      await updateDoc(userDocRef, { role: 'User' });
      setPendingUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Error rejecting user:', error);
    }
  };

  const handleApproveLesson = async (lessonId) => {
    try {
      const lessonDocRef = doc(firestore, 'lessons', lessonId);
      await updateDoc(lessonDocRef, { status: 'approved' });
      setPendingLessons(prevLessons => prevLessons.filter(lesson => lesson.id !== lessonId));
    } catch (error) {
      console.error('Error approving lesson:', error);
    }
  };

  const handleSearch = async () => {
    try {
      const usersCollection = collection(firestore, 'users');
      const displayNameQuery = query(
        usersCollection,
        where('displayName', '>=', searchTerm),
        where('displayName', '<=', searchTerm + '\uf8ff')
      );
      const emailQuery = query(
        usersCollection,
        where('email', '>=', searchTerm),
        where('email', '<=', searchTerm + '\uf8ff')
      );
      const [displayNameSnapshot, emailSnapshot] = await Promise.all([getDocs(displayNameQuery), getDocs(emailQuery)]);

      let usersData = [];
      if (!displayNameSnapshot.empty) {
        usersData = usersData.concat(displayNameSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }
      if (!emailSnapshot.empty) {
        usersData = usersData.concat(emailSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }

      if (usersData.length === 0) {
        setSearchResults([]);
      } else {
        const uniqueUsers = usersData.reduce((acc, user) => {
          if (!acc.find(u => u.id === user.id)) {
            acc.push(user);
          }
          return acc;
        }, []);
        setSearchResults(uniqueUsers);
      }
    } catch (error) {
      console.error('Error searching users:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteDoc(doc(firestore, 'users', userId));
      setSearchResults(prevResults => prevResults.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  // Announcements Handlers
  const handleAddAnnouncement = async () => {
    if (newAnnouncement.trim()) {
      try {
        await addDoc(collection(firestore, 'announcements'), {
          title: newAnnouncement,
          date: new Date().toISOString().split('T')[0],
        });
        setAnnouncements(prev => [...prev, { title: newAnnouncement, date: new Date().toISOString().split('T')[0] }]);
        setNewAnnouncement('');
      } catch (error) {
        console.error('Error adding announcement:', error);
      }
    }
  };

  const handleDeleteAnnouncement = async (id) => {
    try {
      await deleteDoc(doc(firestore, 'announcements', id));
      setAnnouncements(prev => prev.filter(announcement => announcement.id !== id));
    } catch (error) {
      console.error('Error deleting announcement:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.adminDashboardContainer}>
      <div className={styles.adminDashboardHeader}>
        <h1>Admin Dashboard</h1>
        <button onClick={() => window.location.href = '/'}>Back to Main Page</button>
      </div>

      <h2>Pending User Approvals</h2>
      {pendingUsers.length === 0 ? (
        <p>No users pending approval</p>
      ) : (
        <ul>
          {pendingUsers.map(user => (
            <li key={user.id}>
              {user.displayName} - {user.email}
              <div className={styles.buttonGroup}>
                <button onClick={() => handleApproveUser(user.id)}>Approve</button>
                <button className="rejectButton" onClick={() => handleRejectUser(user.id)}>Reject</button>
              </div>
            </li>
          ))}
        </ul>
      )}

      <h2>Pending Lesson Approvals</h2>
      {pendingLessons.length === 0 ? (
        <p>No lessons pending approval</p>
      ) : (
        <ul>
          {pendingLessons.map(lesson => (
            <li key={lesson.id}>
              {lesson.title} - {lesson.category}
              <div className={styles.buttonGroup}>
                <button onClick={() => handleApproveLesson(lesson.id)}>Approve</button>
              </div>
            </li>
          ))}
        </ul>
      )}

      <h2>Delete User</h2>
      <div className={styles.searchContainer}>
        <input
          type="text"
          className="form-control"
          placeholder="Search users by name or email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleSearch} className={styles.searchButton}>Search</button>
      </div>
      {searchResults.length > 0 && (
        <ul>
          {searchResults.map(user => (
            <li key={user.id}>
              {user.displayName} - {user.email}
              <div className={styles.buttonGroup}>
                <button className={`${styles.rejectButton}`} onClick={() => handleDeleteUser(user.id)}>Delete</button>
              </div>
            </li>
          ))}
        </ul>
      )}

      <h2>Announcements</h2>
      <div className={styles.announcementForm}>
        <input
          type="text"
          placeholder="Add a new announcement"
          value={newAnnouncement}
          onChange={(e) => setNewAnnouncement(e.target.value)}
        />
        <button onClick={handleAddAnnouncement} className={styles.addButton}>Add Announcement</button>
      </div>
      <ul>
        {announcements.map(announcement => (
          <li key={announcement.id}>
            <strong>{announcement.date}</strong>: {announcement.title}
            <button onClick={() => handleDeleteAnnouncement(announcement.id)} className={styles.deleteButton}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminDashboard;
