import React, { useEffect, useState, useCallback } from 'react';
import { firestore } from '../firebaseConfig';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import styles from './ReviewAllProgressPage.module.css';

const ReviewAllProgressPage = ({ user }) => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userProgress, setUserProgress] = useState({});
  const [userBadges, setUserBadges] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(false);

  useEffect(() => {
    const schoolsList = [
      'Aberlady Primary School',
      'Athelstaneford Primary School',
      'Campie Primary School',
      'Cockenzie Primary School',
      'Dirleton Primary School',
      'Dunbar Grammar School',
      'Dunbar Primary School',
      'East Linton Primary School',
      'Elphinstone Primary School',
      'Gullane Primary School',
      'Haddington Infant School',
      'Humbie Primary School',
      'Innerwick Primary School',
      'King’s Meadow Primary School',
      'Law Primary School',
      'Longniddry Primary School',
      'Loretto RC Primary School',
      'Macmerry Primary School',
      'Musselburgh Burgh Primary School',
      'North Berwick High School',
      'Ormiston Primary School',
      'Pencaitland Primary School',
      'Pinkie St Peter’s Primary School',
      'Preston Lodge High School',
      'Prestonpans Infant School',
      'Prestonpans Primary School',
      'Ross High School',
      'Saltoun Primary School',
      'Sanderson’s Wynd Primary School',
      'St Gabriel’s RC Primary School',
      'St Martin’s RC Primary School',
      'St Mary’s RC Primary School',
      'Stenton Primary School',
      'Stoneyhill Primary School',
      'Wallyford Primary School',
      'West Barns Primary School',
      'Whitecraig Primary School',
      'Windygoul Primary School',
      'Yester Primary School',
      'Central Education'
    ];
    setSchools(schoolsList);
  }, []);

  useEffect(() => {
    if (user.role.startsWith('head')) {
      const school = user.role.replace('head', '').replace(/([A-Z])/g, ' $1').trim() + ' Primary School';
      setSelectedSchool(school);
      handleSchoolChange({ target: { value: school } });
    }
  }, [user]);

  const handleSchoolChange = async (e) => {
    setSelectedSchool(e.target.value);
    setLoading(true);
    const usersQuery = query(collection(firestore, 'users'), where('school', '==', e.target.value));
    const usersSnapshot = await getDocs(usersQuery);
    const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(usersData);
    setLoading(false);
  };

  const handleUserChange = async (e) => {
    setSelectedUser(e.target.value);
    setLoading(true);

    try {
      const userProgressCollection = collection(firestore, 'users', e.target.value, 'completedLessons');
      const userProgressSnapshot = await getDocs(userProgressCollection);

      const progressData = await Promise.all(
        userProgressSnapshot.docs.map(async (docSnapshot) => {
          const lessonDocRef = doc(firestore, 'lessons', docSnapshot.id);
          const lessonDoc = await getDoc(lessonDocRef);

          if (lessonDoc.exists()) {
            const lessonData = lessonDoc.data();
            return {
              id: docSnapshot.id,
              title: lessonData.title,
              category: lessonData.category,
              tileCategory: lessonData.tileCategory,
            };
          } else {
            console.error(`Lesson document with ID ${docSnapshot.id} does not exist.`);
            return null;
          }
        })
      );

      const validProgressData = progressData.filter((progress) => progress !== null);
      setUserProgress(groupByCategoryAndTile(validProgressData));

      // Fetch user badges
      const userBadgesCollection = collection(firestore, 'users', e.target.value, 'awardedBadges');
      const userBadgesSnapshot = await getDocs(userBadgesCollection);
      const badgesData = userBadgesSnapshot.docs.map(doc => doc.data());
      setUserBadges({ [e.target.value]: badgesData });

    } catch (error) {
      console.error('Error fetching user progress or badges:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleListAllUsers = async () => {
    setShowAllUsers(true);
    setLoading(true);
    const progressData = {};

    for (const user of users) {
      try {
        const userProgressCollection = collection(firestore, 'users', user.id, 'completedLessons');
        const userProgressSnapshot = await getDocs(userProgressCollection);
        const lessons = await Promise.all(
          userProgressSnapshot.docs.map(async (docSnapshot) => {
            const lessonDocRef = doc(firestore, 'lessons', docSnapshot.id);
            const lessonDoc = await getDoc(lessonDocRef);
            if (lessonDoc.exists()) {
              return {
                id: docSnapshot.id,
                title: lessonDoc.data().title,
                category: lessonDoc.data().category,
                tileCategory: lessonDoc.data().tileCategory,
              };
            } else {
              console.error(`Lesson document with ID ${docSnapshot.id} does not exist.`);
              return null;
            }
          })
        );
        progressData[user.id] = groupByCategoryAndTile(lessons.filter(lesson => lesson !== null));

      } catch (error) {
        console.error('Error fetching user progress:', error);
      }
    }

    setUserProgress(progressData);
    setLoading(false);
  };

  const groupByCategoryAndTile = (lessons) => {
    const grouped = {};
    lessons.forEach((lesson) => {
      if (!grouped[lesson.tileCategory]) {
        grouped[lesson.tileCategory] = {};
      }
      if (!grouped[lesson.tileCategory][lesson.category]) {
        grouped[lesson.tileCategory][lesson.category] = [];
      }
      grouped[lesson.tileCategory][lesson.category].push(lesson);
    });
    return grouped;
  };

  const renderLessonGroups = (lessonGroups) => {
    return Object.keys(lessonGroups).map(tileCategory => (
      <div key={tileCategory}>
        <h4>{tileCategory}</h4>
        {Object.keys(lessonGroups[tileCategory]).map(category => {
          const lessonsArray = lessonGroups[tileCategory][category];  // Corrected variable name
          return (
            <div key={category} style={{ marginLeft: '20px' }}>
              <h5>{category}</h5>
              <ul>
                {Array.isArray(lessonsArray) && lessonsArray.map(lesson => (  // Corrected variable name
                  <li key={lesson.id}>{lesson.title}</li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    ));
  };

  return (
    <div className={styles.reviewAllProgressPageContainer}>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <div className={styles.mainContentCard}>
            <div className={styles.cardHeader}>
              <h1>Review All Progress</h1>
              <div>
                <Link to="/" className={`${styles.btn} ${styles.btnAnimated} ${styles.btnSecondary}`}>
                  Back to Lessons
                </Link>
                <button 
                  onClick={handleListAllUsers} 
                  className={`${styles.btn} ${styles.btnAnimated}`}
                  disabled={loading}
                >
                  List All Users
                </button>
              </div>
            </div>
            <div className={styles.cardBody}>
              {!showAllUsers && selectedSchool && (
                <div className="form-group mt-3">
                  <label htmlFor="userSelect">Select User</label>
                  <select
                    id="userSelect"
                    className="form-control"
                    value={selectedUser || ''}
                    onChange={handleUserChange}
                  >
                    <option value="">Select User</option>
                    {users.map((user, index) => (
                      <option key={index} value={user.id}>
                        {user.displayName ? `${user.displayName} (${user.email})` : user.email}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {selectedUser && !showAllUsers && (
                <div className="mt-4">
                  <h2>Progress for {users.find(u => u.id === selectedUser)?.displayName || users.find(u => u.id === selectedUser)?.email}</h2>
                  {loading ? (
                    <p>Loading user progress...</p>
                  ) : (
                    <>
                      <h3>Completed Lessons</h3>
                      {renderLessonGroups(userProgress)}

                      <h3 className="mt-4">Earned Badges</h3>
                      <div className={styles.badgesContainer}>
                        {userBadges[selectedUser] && userBadges[selectedUser].length > 0 ? (
                          userBadges[selectedUser].map((badge, index) => (
                            <div key={index} className={styles.badgeCard}>
                              <img src={badge.iconUrl} alt={badge.name} className={styles.badgeIcon} />
                              <span>{badge.name}</span>
                              <span className={styles.badgeAwardedDate}>Awarded on {new Date(badge.awardedDate).toLocaleDateString()}</span>
                            </div>
                          ))
                        ) : (
                          <p>No badges earned yet.</p>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
              {showAllUsers && selectedSchool && (
                <div className={styles.userListContainer}>
                  {loading ? (
                    <p>Loading user progress...</p>
                  ) : (
                    users.map(user => (
                      <div key={user.id} className={styles.userCard}>
                        <div className={styles.userCardHeader}>
                          <h3>{user.displayName || user.email}</h3>
                        </div>
                        {userProgress[user.id] ? (
                          <>
                            <div className={styles.lessonsDropdown}>
                              <h4>Completed Lessons</h4>
                              {renderLessonGroups(userProgress[user.id])}
                            </div>
                          </>
                        ) : (
                          <p>No progress data available</p>
                        )}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewAllProgressPage;