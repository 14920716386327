import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore } from '../firebaseConfig';
import { doc, getDoc, setDoc, getDocs, collection } from 'firebase/firestore';
import TutorialStep from './TutorialStep';
import QuizPage from './NewQuizPage';
import QuizResultsPage from './QuizResultsPage';
import './Home.module.css';

const Home = ({ user }) => {
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const [steps, setSteps] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [showQuizPage, setShowQuizPage] = useState(false);
  const [showCompletedPage, setShowCompletedPage] = useState(false);
  const [quizResults, setQuizResults] = useState(null);

  useEffect(() => {
    const fetchLessonData = async () => {
      try {
        const stepsCollection = await getDocs(collection(firestore, 'lessons', lessonId, 'steps'));
        const stepsData = stepsCollection.docs.map(stepDoc => ({
          id: stepDoc.id,
          ...stepDoc.data()
        })).sort((a, b) => a.id.localeCompare(b.id));

        if (stepsData.length > 0) {
          setSteps(stepsData);
        }
      } catch (error) {
        console.error('Error fetching lesson data:', error);
      }
    };

    fetchLessonData();
  }, [lessonId]);

  const nextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      setShowQuizPage(true);
    }
  };

  const prevStep = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const awardBadge = async (userId, badgeId) => {
    const badgeDoc = await getDoc(doc(firestore, 'badges', badgeId));
    if (badgeDoc.exists()) {
      await setDoc(doc(firestore, 'users', userId, 'awardedBadges', badgeId), {
        ...badgeDoc.data(),
        awardedDate: new Date().toISOString(),
      });
    }
  };

  const submitQuiz = async (answers, questions) => {
    const results = questions.map(question => {
      const correctAnswers = Array.isArray(question.correctAnswer) ? question.correctAnswer : [question.correctAnswer];
      const selectedAnswers = answers[question.id] || [];
      const correctCount = selectedAnswers.filter(answer => correctAnswers.includes(answer)).length;
      const incorrectAnswers = selectedAnswers.filter(answer => !correctAnswers.includes(answer));
      const correct = correctCount === correctAnswers.length && incorrectAnswers.length === 0;

      return {
        question: question.question,
        selectedAnswers,
        correctAnswers,
        allAnswers: question.options, // Include all possible answers
        correctCount,
        totalCorrect: correctAnswers.length,
        incorrectAnswers,
        isCorrect: correct
      };
    });

    setQuizResults(results);
    setShowQuizPage(false);
    setShowCompletedPage(true);

    if (user) {
      await setDoc(doc(firestore, 'users', user.uid, 'completedLessons', lessonId), {
        lessonId,
        completionDate: new Date().toISOString(),
      });

      const completedLessonsSnapshot = await getDocs(collection(firestore, 'users', user.uid, 'completedLessons'));
      if (completedLessonsSnapshot.docs.length === 1) {
        await awardBadge(user.uid, 'first_lesson_completed');
      }
    }
  };

  return (
    <div className="container mt-4">
      {showQuizPage ? (
        <QuizPage lessonId={lessonId} onSubmitQuiz={submitQuiz} />
      ) : showCompletedPage ? (
        <QuizResultsPage quizResults={quizResults} onFinishLesson={() => navigate('/')} />
      ) : (
        <>
          {steps.length > 0 ? (
            <TutorialStep
              step={steps[currentStepIndex]}
              nextStep={nextStep}
              prevStep={currentStepIndex > 0 ? prevStep : null}
              isLastStep={currentStepIndex === steps.length - 1}
            />
          ) : (
            <p>Loading steps...</p>
          )}
        </>
      )}
    </div>
  );
};

export default Home;