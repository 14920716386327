// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserSessionPersistence, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyB0wCtzDKjYGid1g5EUqfrNR7L5ZJ5Q8UY",
  authDomain: "lessons-6eba1.firebaseapp.com",
  projectId: "lessons-6eba1",
  storageBucket: "lessons-6eba1.appspot.com",
  messagingSenderId: "1451460343",
  appId: "1:1451460343:web:d5c35f95d7e717b9d1b5f2",
  measurementId: "G-P8HBSXPFFW"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);

const provider = new GoogleAuthProvider();

// Set session persistence
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    // Existing and future Auth states are now persisted in the current session only.
    // New sign-in will be persisted with session persistence.
  })
  .catch((error) => {
    // Handle Errors here.
    console.error('Error setting session persistence:', error);
  });

export { auth, firestore, provider };
