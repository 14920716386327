import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MainPage from './components/MainPage';
import Home from './components/Home';
import ProgressPage from './components/ProgressPage';
import LessonCreator from './components/LessonCreator';
import LessonEditor from './components/LessonEditor';
import Auth from './components/Auth';
import ProtectedRoute from './components/ProtectedRoute';
import SelectSchool from './components/SelectSchool';
import ReviewAllProgressPage from './components/ReviewAllProgressPage';
import CLPLCalendar from './components/CLPLCalendar';
import QuizResultsPage from './components/QuizResultsPage';
import AdminDashboard from './components/AdminDashboard';
import { auth, firestore } from './firebaseConfig';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import './App.css';
import './index.css';


const App = () => {
  const location = useLocation();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          user = { ...user, role: userData.role, displayName: userData.displayName, school: userData.school };
        } else {
          await setDoc(userDocRef, {
            displayName: user.displayName,
            email: user.email,
            role: 'user',
            school: ''
          });
          user = { ...user, role: 'user', displayName: user.displayName, school: '' };
        }
      }
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleUserChange = (user) => {
    setUser(user);
  };

  return (
    <div className="app-container">
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Routes location={location}>
            <Route path="/login" element={<Auth onUserChange={handleUserChange} />} />
            <Route path="/select-school" element={<ProtectedRoute user={user}><SelectSchool user={user} /></ProtectedRoute>} />
            <Route path="/" element={<ProtectedRoute user={user}><MainPage user={user} /></ProtectedRoute>} />
            <Route path="/lesson/:lessonId" element={<ProtectedRoute user={user}><Home user={user} /></ProtectedRoute>} />
            <Route path="/progress" element={<ProtectedRoute user={user}><ProgressPage user={user} /></ProtectedRoute>} />
            <Route path="/create-lesson" element={<ProtectedRoute user={user}><LessonCreator user={user} /></ProtectedRoute>} />
            <Route path="/edit-lesson/:lessonId" element={<ProtectedRoute user={user}><LessonEditor user={user} /></ProtectedRoute>} />
            <Route 
              path="/review-all-progress" 
              element={<ProtectedRoute user={user} allowedRoles={['admin', ...headTeacherRoles]}><ReviewAllProgressPage user={user} /></ProtectedRoute>} 
            />
            <Route path="/calendar" element={<ProtectedRoute user={user} allowedRoles={['admin', 'Staff', ...headTeacherRoles]}><CLPLCalendar user={user} /></ProtectedRoute>} />
            <Route path="/quiz-results" element={<ProtectedRoute user={user}><QuizResultsPage user={user} /></ProtectedRoute>} />
            <Route path="/admin-dashboard" element={<ProtectedRoute user={user} allowedRoles={['admin']}><AdminDashboard user={user} /></ProtectedRoute>} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

const headTeacherRoles = [
  'headAberlady', 'headAthelstaneford', 'headCampie', 'headCockenzie', 'headDirleton',
  'headDunbarGrammar', 'headDunbarPrimary', 'headEastLinton', 'headElphinstone',
  'headGullane', 'headHaddington', 'headInnerwick', 'headKnox', 'headLaw',
  'headLethamMains', 'headLongniddry', 'headLoretto', 'headMacmerry', 'headMusselburghBurgh',
  'headMusselburghGrammar', 'headNorthBerwick', 'headOrmiston', 'headPencaitland',
  'headPinkie', 'headPrestonLodge', 'headPrestonTower', 'headRosehill', 'headRoss',
  'headSaltoun', 'headSandersonsWynd', 'headStGabriels', 'headStMartins', 'headStMarys',
  'headStenton', 'headStoneyhill', 'headWallyford', 'headWestBarns', 'headWhitecraig',
  'headWindygoul', 'headYester', 'headJMH'
];

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
