import React, { useState, useEffect } from 'react';
import { firestore } from '../firebaseConfig';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { useParams, useNavigate, Link } from 'react-router-dom';
import styles from './LessonEditor.module.css';

const LessonEditor = ({ user }) => {
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const [lessonTitle, setLessonTitle] = useState('');
  const [lessonDescription, setLessonDescription] = useState('');
  const [lessonCategory, setLessonCategory] = useState('');
  const [badgeUrl, setBadgeUrl] = useState('');
  const [badgeName, setBadgeName] = useState('');
  const [badgeSummary, setBadgeSummary] = useState('');
  const [audience, setAudience] = useState('Both');
  const [level, setLevel] = useState('Beginner'); // New state for lesson level
  const [steps, setSteps] = useState([{ title: '', content: '', mediaUrl: '' }]);
  const [quiz, setQuiz] = useState([{ question: '', options: [''], correctAnswers: [] }]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLessonData = async () => {
      try {
        const lessonDoc = await getDoc(doc(firestore, 'lessons', lessonId));
        if (lessonDoc.exists()) {
          const lessonData = lessonDoc.data();
          setLessonTitle(lessonData.title);
          setLessonDescription(lessonData.description);
          setLessonCategory(lessonData.category);
          setBadgeUrl(lessonData.badgeUrl);
          setBadgeName(lessonData.badgeName);
          setBadgeSummary(lessonData.badgeSummary || '');
          setAudience(lessonData.audience || 'Both');
          setLevel(lessonData.level || 'Beginner'); // Load the level from Firestore

          const stepsCollection = await getDocs(collection(firestore, 'lessons', lessonId, 'steps'));
          const stepsData = stepsCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setSteps(stepsData);

          const quizCollection = await getDocs(collection(firestore, 'lessons', lessonId, 'quiz'));
          const quizData = quizCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setQuiz(quizData);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching lesson data:', error);
        setLoading(false);
      }
    };

    fetchLessonData();
  }, [lessonId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateDoc(doc(firestore, 'lessons', lessonId), {
        title: lessonTitle,
        description: lessonDescription,
        category: lessonCategory,
        badgeUrl,
        badgeName,
        badgeSummary,
        audience,
        level, // Save the level to Firestore
      });

      const stepsCollection = collection(firestore, 'lessons', lessonId, 'steps');
      for (const step of steps) {
        await updateDoc(doc(stepsCollection, step.id), step);
      }

      const quizCollection = collection(firestore, 'lessons', lessonId, 'quiz');
      for (const question of quiz) {
        await updateDoc(doc(quizCollection, question.id), question);
      }

      navigate('/');
    } catch (error) {
      console.error('Error updating lesson:', error);
    }
  };

  const handleStepChange = (index, event) => {
    const newSteps = [...steps];
    newSteps[index][event.target.name] = event.target.value;
    setSteps(newSteps);
  };

  const handleQuizChange = (questionIndex, event) => {
    const newQuiz = [...quiz];
    newQuiz[questionIndex][event.target.name] = event.target.value;
    setQuiz(newQuiz);
  };

  const handleOptionChange = (questionIndex, optionIndex, event) => {
    const newQuiz = [...quiz];
    newQuiz[questionIndex].options[optionIndex] = event.target.value;
    setQuiz(newQuiz);
  };

  const handleAddStep = () => {
    setSteps([...steps, { title: '', content: '', mediaUrl: '' }]);
  };

  const handleRemoveStep = (index) => {
    const newSteps = [...steps];
    newSteps.splice(index, 1);
    setSteps(newSteps);
  };

  if (loading) {
    return <p>Loading lesson data...</p>;
  }

  return (
    <div className={styles.lessonEditorWrapper}>
      <div className={styles.lessonEditorContainer}>
        <div className={`card mb-4 ${styles.card}`}>
          <div className={`card-header d-flex justify-content-between align-items-center ${styles.cardHeader}`}>
            <h1>Edit Lesson</h1>
            <Link to="/" className="btn btn-secondary">
              Back to Lessons
            </Link>
          </div>
          <div className={`card-body ${styles.cardBody}`}>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Lesson Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={lessonTitle}
                  onChange={(e) => setLessonTitle(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Lesson Description</label>
                <textarea
                  className="form-control"
                  id="description"
                  rows="3"
                  value={lessonDescription}
                  onChange={(e) => setLessonDescription(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="category">Category</label>
                <input
                  type="text"
                  className="form-control"
                  id="category"
                  value={lessonCategory}
                  onChange={(e) => setLessonCategory(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="badgeName">Badge Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="badgeName"
                  value={badgeName}
                  onChange={(e) => setBadgeName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="badgeUrl">Badge Image URL</label>
                <input
                  type="text"
                  className="form-control"
                  id="badgeUrl"
                  value={badgeUrl}
                  onChange={(e) => setBadgeUrl(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="badgeSummary">Badge Summary</label>
                <textarea
                  className="form-control"
                  id="badgeSummary"
                  rows="3"
                  value={badgeSummary}
                  onChange={(e) => setBadgeSummary(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="audience">Audience</label>
                <select
                  className="form-control"
                  id="audience"
                  value={audience}
                  onChange={(e) => setAudience(e.target.value)}
                  required
                >
                  <option value="Both">Both</option>
                  <option value="Pupil">Pupil</option>
                  <option value="Staff">Staff</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="level">Lesson Level</label> {/* New field for selecting lesson level */}
                <select
                  className="form-control"
                  id="level"
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                  required
                >
                  <option value="Beginner">Beginner</option>
                  <option value="Intermediate">Intermediate</option>
                  <option value="Advanced">Advanced</option>
                </select>
              </div>
              <hr />
              <h4>Lesson Content</h4>
              {steps.map((step, index) => (
                <div key={index} className="card mb-2">
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor={`step-title-${index}`}>Lesson Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id={`step-title-${index}`}
                        name="title"
                        value={step.title}
                        onChange={(e) => handleStepChange(index, e)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor={`step-content-${index}`}>Lesson Description</label>
                      <textarea
                        className="form-control"
                        id={`step-content-${index}`}
                        name="content"
                        rows="3"
                        value={step.content}
                        onChange={(e) => handleStepChange(index, e)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor={`step-mediaUrl-${index}`}>Media URL (YouTube or Image)</label>
                      <input
                        type="text"
                        className="form-control"
                        id={`step-mediaUrl-${index}`}
                        name="mediaUrl"
                        value={step.mediaUrl}
                        onChange={(e) => handleStepChange(index, e)}
                      />
                    </div>
                    <div className="text-end">
                      <button type="button" className="btn btn-danger" onClick={() => handleRemoveStep(index)}>
                        Remove Lesson Content
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-center">
                <button type="button" className="btn btn-secondary mb-4" onClick={handleAddStep}>
                  Add Lesson Content
                </button>
              </div>
              <hr />
              <h4>Quiz</h4>
              {quiz.map((question, questionIndex) => (
                <div key={questionIndex} className="card mb-2">
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor={`question-${questionIndex}`}>Question</label>
                      <input
                        type="text"
                        className="form-control"
                        id={`question-${questionIndex}`}
                        name="question"
                        value={question.question}
                        onChange={(e) => handleQuizChange(questionIndex, e)}
                        required
                      />
                    </div>
                    {question.options.map((option, optionIndex) => (
                      <div key={optionIndex} className="form-group">
                        <label htmlFor={`option-${questionIndex}-${optionIndex}`}>Option {optionIndex + 1}</label>
                        <input
                          type="text"
                          className="form-control"
                          id={`option-${questionIndex}-${optionIndex}`}
                          value={option}
                          onChange={(e) => handleOptionChange(questionIndex, optionIndex, e)}
                          required
                        />
                      </div>
                    ))}
                    <div className="form-group">
                      <label htmlFor={`correctAnswer-${questionIndex}`}>Correct Answer(s)</label>
                      <input
                        type="text"
                        className="form-control"
                        id={`correctAnswer-${questionIndex}`}
                        name="correctAnswers"
                        value={question.correctAnswers}
                        onChange={(e) => handleQuizChange(questionIndex, e)}
                        required
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-center">
                <button type="submit" className={`btn btn-primary ${styles.btnAnimated}`}>
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonEditor;
