import React, { useEffect, useState } from 'react';
import { firestore } from '../firebaseConfig';
import { collection, getDocs, doc, getDoc, updateDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import styles from './ProgressPage.module.css'; 
import { FaInfoCircle, FaClipboard } from 'react-icons/fa';

const roleDisplayNameMap = {
  headAberlady: 'Aberlady Admin',
  headAthelstaneford: 'Athelstaneford Admin',
  headCampie: 'Campie Admin',
  headCockenzie: 'Cockenzie Admin',
  headDirleton: 'Dirleton Admin',
  headDunbarGrammar: 'Dunbar Grammar Admin',
  headDunbarPrimary: 'Dunbar Primary Admin',
  headEastLinton: 'East Linton Admin',
  headElphinstone: 'Elphinstone Admin',
  headGullane: 'Gullane Admin',
  headHaddington: 'Haddington Admin',
  headInnerwick: 'Innerwick Admin',
  headKnox: 'Knox Admin',
  headLaw: 'Law Admin',
  headLethamMains: 'Letham Mains Admin',
  headLongniddry: 'Longniddry Admin',
  headLoretto: 'Loretto Admin',
  headMacmerry: 'Macmerry Admin',
  headMusselburghBurgh: 'Musselburgh Burgh Admin',
  headMusselburghGrammar: 'Musselburgh Grammar Admin',
  headNorthBerwick: 'North Berwick High Admin',
  headOrmiston: 'Ormiston Admin',
  headPencaitland: 'Pencaitland Admin',
  headPinkie: 'Pinkie Admin',
  headPrestonLodge: 'Preston Lodge Admin',
  headPrestonTower: 'Preston Tower Admin',
  headRosehill: 'Rosehill Admin',
  headRoss: 'Ross High Admin',
  headSaltoun: 'Saltoun Admin',
  headSandersonsWynd: 'Sandersons Wynd Admin',
  headStGabriels: 'St Gabriels Admin',
  headStMartins: 'St Martin\'s Admin',
  headStMarys: 'St Mary\'s Admin',
  headStenton: 'Stenton Admin',
  headStoneyhill: 'Stoneyhill Admin',
  headWallyford: 'Wallyford Admin',
  headWestBarns: 'West Barns Admin',
  headWhitecraig: 'Whitecraig Admin',
  headWindygoul: 'Windygoul Admin',
  headYester: 'Yester Admin',
  headJMH: 'JMH Admin',
};

const schools = [
  'Aberlady Primary School',
  'Athelstaneford Primary School',
  'Campie Primary School',
  'Cockenzie Primary School',
  'Dirleton Primary School',
  'Dunbar Grammar School',
  'Dunbar Primary School',
  'East Linton Primary School',
  'Elphinstone Primary School',
  'Gullane Primary School',
  'Haddington Infant School',
  'Humbie Primary School',
  'Innerwick Primary School',
  'King’s Meadow Primary School',
  'Law Primary School',
  'Longniddry Primary School',
  'Loretto RC Primary School',
  'Macmerry Primary School',
  'Musselburgh Burgh Primary School',
  'North Berwick High School',
  'Ormiston Primary School',
  'Pencaitland Primary School',
  'Pinkie St Peter’s Primary School',
  'Preston Lodge High School',
  'Prestonpans Infant School',
  'Prestonpans Primary School',
  'Ross High School',
  'Saltoun Primary School',
  'Sanderson’s Wynd Primary School',
  'St Gabriel’s RC Primary School',
  'St Martin’s RC Primary School',
  'St Mary’s RC Primary School',
  'Stenton Primary School',
  'Stoneyhill Primary School',
  'Wallyford Primary School',
  'West Barns Primary School',
  'Whitecraig Primary School',
  'Windygoul Primary School',
  'Yester Primary School',
  'Central Education'
];

const ProgressPage = ({ user }) => {
  const [completedLessons, setCompletedLessons] = useState([]);
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBadgeSummary, setSelectedBadgeSummary] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSchoolModalOpen, setIsSchoolModalOpen] = useState(false);
  const [newSchool, setNewSchool] = useState('');

  const awardBadgeToUser = async (userId, badgeData) => {
    const badgeDocRef = doc(firestore, 'users', userId, 'awardedBadges', badgeData.id);
    const badgeDoc = await getDoc(badgeDocRef);
    if (!badgeDoc.exists()) {
      await setDoc(badgeDocRef, {
        ...badgeData,
        awardedDate: serverTimestamp(),
      });
    }
  };

  useEffect(() => {
    const fetchCompletedLessons = async () => {
      try {
        const completedLessonsCollection = collection(firestore, 'users', user.uid, 'completedLessons');
        const completedLessonsSnapshot = await getDocs(completedLessonsCollection);

        const completedLessonsData = await Promise.all(
          completedLessonsSnapshot.docs.map(async (docSnapshot) => {
            const lessonDoc = await getDoc(doc(firestore, 'lessons', docSnapshot.id));
            if (lessonDoc.exists()) {
              const lessonData = lessonDoc.data();
              
              if (lessonData.badgeName && lessonData.badgeUrl) {
                await awardBadgeToUser(user.uid, {
                  id: lessonDoc.id,
                  name: lessonData.badgeName,
                  iconUrl: lessonData.badgeUrl,
                  summary: lessonData.badgeSummary || '',
                });
              }

              return {
                ...docSnapshot.data(),
                lessonId: docSnapshot.id,
                title: lessonData.title,
                category: lessonData.category,
                badgeUrl: lessonData.badgeUrl,
                badgeName: lessonData.badgeName,
                summary: lessonData.summary || '',
              };
            } else {
              console.error(`No such document for lesson ${docSnapshot.id}`);
              return null;
            }
          })
        );

        const validCompletedLessonsData = completedLessonsData.filter(lesson => lesson !== null);
        setCompletedLessons(validCompletedLessonsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching completed lessons:', error);
        setLoading(false);
      }
    };

    const fetchBadges = async () => {
      try {
        const badgesCollection = collection(firestore, 'users', user.uid, 'awardedBadges');
        const badgesSnapshot = await getDocs(badgesCollection);
        const badgesData = badgesSnapshot.docs.map(doc => doc.data()).sort((a, b) => a.name.localeCompare(b.name));
        setBadges(badgesData);
      } catch (error) {
        console.error('Error fetching badges:', error);
      }
    };

    fetchCompletedLessons();
    fetchBadges();
  }, [user]);

  const groupByCategory = (lessons) => {
    return lessons.reduce((acc, lesson) => {
      if (!acc[lesson.category]) {
        acc[lesson.category] = [];
      }
      acc[lesson.category].push(lesson);
      return acc;
    }, {});
  };

  const getDisplayRole = (role) => {
    return roleDisplayNameMap[role] || role;
  };

  const groupedLessons = groupByCategory(completedLessons);

  const handleBadgeClick = (summary) => {
    setSelectedBadgeSummary(summary);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBadgeSummary(null);
  };

  const copyToClipboard = () => {
    if (selectedBadgeSummary) {
      navigator.clipboard.writeText(selectedBadgeSummary)
        .then(() => alert('Badge summary copied to clipboard!'))
        .catch((error) => alert('Failed to copy badge summary: ', error));
    }
  };

  const openSchoolModal = () => {
    setIsSchoolModalOpen(true);
  };

  const closeSchoolModal = () => {
    setIsSchoolModalOpen(false);
    setNewSchool('');
  };

  const handleSchoolChange = async () => {
    if (newSchool) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, { school: newSchool });
        closeSchoolModal();
        window.location.reload(); 
      } catch (error) {
        console.error('Error updating school:', error);
      }
    }
  };

  if (loading) {
    return <p>Loading your progress...</p>;
  }

  return (
    <div className={styles.profilePageContainer}>
      <div className={styles.profileHeader}>
        <img
          src={user?.photoURL || 'https://live.staticflickr.com/65535/53959840387_d85febd872_o.png'}
          alt="Profile"
          onError={(e) => { e.target.src = 'https://static.vecteezy.com/system/resources/previews/021/548/095/original/default-profile-picture-avatar-user-avatar-icon-person-icon-head-icon-profile-picture-icons-default-anonymous-user-male-and-female-businessman-photo-placeholder-social-network-avatar-portrait-free-vector.jpg'; }}
          className={styles.userAvatar}
        />
        <div className={styles.userInfo}>
          <p className={styles.userName}>{user.displayName || 'User Name'}</p>
          <p>Email: {user.email || 'user@example.com'}</p>
          <p>Role: {getDisplayRole(user.role) || 'User Role'}</p>
          <p>Current School: {user.school || 'School Name'}</p>
        </div>
        <div className={styles.headerButtons}>
          <Link to="/" className={`${styles.btn} ${styles.btnSecondary}`}>
            Back to Lessons
          </Link>
          <button onClick={openSchoolModal} className={styles.btn}>
            Change School
          </button>
        </div>
      </div>

      <div className={styles.profileContent}>
        {Object.keys(groupedLessons).length > 0 ? (
          Object.keys(groupedLessons).map((category) => (
            <div key={category} className={styles.categorySection}>
              <h2>{category}</h2>
              <ul className={styles.lessonList}>
                {groupedLessons[category].map((lesson) => (
                  <li key={lesson.lessonId} className={styles.listGroupItem}>
                    <span>
                      {lesson.title} - Completed on {new Date(lesson.completionDate).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <p>You haven't completed any lessons yet.</p>
        )}

        {badges.length > 0 && (
          <div className={styles.section}>
            <h2>Earned Badges</h2>
            <div className={styles.badgeGrid}>
              {badges.map((badge, index) => (
                <div key={index} className={styles.badgeCard}>
                  <img src={badge.iconUrl} alt={badge.name} className={styles.badgeIcon} />
                  <span>{badge.name}</span>
                  <span className={styles.badgeAwardedDate}>
                    Awarded on {new Date(badge.awardedDate?.toDate?.() || badge.awardedDate).toLocaleDateString()}
                  </span>
                  <FaInfoCircle
                    className={styles.infoIcon}
                    onClick={() => handleBadgeClick(badge.summary)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.closeButton} onClick={closeModal}>&times;</span>
            <h3>Badge Summary</h3>
            <p>{selectedBadgeSummary}</p>
            <div className={styles.modalButtonContainer}>
              <button onClick={copyToClipboard} className={styles.copyButton}>
                <FaClipboard />
              </button>
              <button onClick={closeModal} className={styles.btn}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {isSchoolModalOpen && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <span className={styles.closeButton} onClick={closeSchoolModal}>&times;</span>
            <h3>Select a New School</h3>
            <select
              value={newSchool}
              onChange={(e) => setNewSchool(e.target.value)}
              className={styles.formControl}
            >
              <option value="">Select a school</option>
              {schools.map((school, index) => (
                <option key={index} value={school}>{school}</option>
              ))}
            </select>
            <button onClick={handleSchoolChange} className={styles.btn}>
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressPage;
