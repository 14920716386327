import React, { useState, useEffect } from 'react';
import { firestore } from '../firebaseConfig';
import { collection, addDoc, getDocs, serverTimestamp } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import styles from './LessonCreator.module.css';

const LessonCreator = ({ user }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [tileCategory, setTileCategory] = useState('');
  const [badgeUrl, setBadgeUrl] = useState('');
  const [badgeName, setBadgeName] = useState('');
  const [badgeSummary, setBadgeSummary] = useState('');
  const [level, setLevel] = useState('Beginner'); // New state for lesson level
  const [steps, setSteps] = useState([{ title: '', content: '', mediaUrl: '' }]);
  const [quiz, setQuiz] = useState([{ question: '', options: [''], correctAnswerIndex: 0, type: '' }]);
  const [audience, setAudience] = useState('Both');
  const [showLessonContent, setShowLessonContent] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [existingCategories, setExistingCategories] = useState([]);
  const [existingTileCategories, setExistingTileCategories] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const lessonsCollection = collection(firestore, 'lessons');
        const lessonsSnapshot = await getDocs(lessonsCollection);

        const categories = new Set();
        const tileCategories = new Set();

        lessonsSnapshot.forEach(doc => {
          const data = doc.data();
          if (data.category) categories.add(data.category);
          if (data.tileCategory) tileCategories.add(data.tileCategory);
        });

        setExistingCategories(Array.from(categories));
        setExistingTileCategories(Array.from(tileCategories));
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleToggleLessonContent = () => setShowLessonContent(!showLessonContent);
  const handleToggleQuiz = () => setShowQuiz(!showQuiz);

  const handleAddStep = () => setSteps([...steps, { title: '', content: '', mediaUrl: '' }]);
  const handleRemoveStep = (index) => setSteps(steps.filter((_, i) => i !== index));
  const handleStepChange = (index, event) => setSteps(steps.map((step, i) => i === index ? { ...step, [event.target.name]: event.target.value } : step));

  const handleAddOption = (questionIndex) => setQuiz(quiz.map((q, i) => i === questionIndex ? { ...q, options: [...q.options, ''] } : q));
  const handleRemoveOption = (questionIndex, optionIndex) => setQuiz(quiz.map((q, i) => i === questionIndex ? { ...q, options: q.options.filter((_, oi) => oi !== optionIndex) } : q));
  const handleQuizChange = (questionIndex, event) => setQuiz(quiz.map((q, i) => i === questionIndex ? { ...q, [event.target.name]: event.target.value } : q));
  const handleOptionChange = (questionIndex, optionIndex, event) => setQuiz(quiz.map((q, i) => i === questionIndex ? { ...q, options: q.options.map((opt, oi) => oi === optionIndex ? event.target.value : opt) } : q));
  const handleCorrectAnswerChange = (questionIndex, optionIndex) => setQuiz(quiz.map((q, i) => i === questionIndex ? { ...q, correctAnswerIndex: optionIndex } : q));

  const handleAddQuestion = () => setQuiz([...quiz, { question: '', options: [''], correctAnswerIndex: 0, type: '' }]);
  const handleRemoveQuestion = (index) => setQuiz(quiz.filter((_, i) => i !== index));

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const lessonDoc = await addDoc(collection(firestore, 'lessons'), {
        title, description, category, tileCategory, badgeUrl, badgeName, badgeSummary, audience, level, // Include the level here
        status: 'pending', createdAt: serverTimestamp(), updatedAt: serverTimestamp()
      });

      const stepsCollection = collection(firestore, 'lessons', lessonDoc.id, 'steps');
      for (const step of steps) {
        await addDoc(stepsCollection, step);
      }

      const quizCollection = collection(firestore, 'lessons', lessonDoc.id, 'quiz');
      for (const question of quiz) {
        await addDoc(quizCollection, question);
      }

      navigate('/');
    } catch (error) {
      console.error('Error creating lesson:', error);
    }
  };

  return (
    <div className={styles.lessonCreatorWrapper}>
      <div className={styles.lessonCreatorContainer}>
        <div className={`card-header d-flex justify-content-between align-items-center ${styles.cardHeader}`}>
          <h1>Create a New Lesson</h1>
          <Link to="/" className="btn btn-secondary">Back to Lessons</Link>
        </div>
        <div className={`card-body ${styles.cardBody}`}>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Lesson Title</label>
              <input type="text" className="form-control" id="title" placeholder="Enter lesson title" value={title} onChange={(e) => setTitle(e.target.value)} required />
            </div>
            <div className="form-group">
              <label htmlFor="description">Lesson Description</label>
              <textarea className="form-control" id="description" rows="3" placeholder="Enter lesson description" value={description} onChange={(e) => setDescription(e.target.value)} required />
            </div>
            <div className="form-group">
              <label htmlFor="category">Category</label>
              <input 
                list="categoryList" 
                type="text" 
                className="form-control" 
                id="category" 
                placeholder="Enter or select a category" 
                value={category} 
                onChange={(e) => setCategory(e.target.value)} 
                required 
              />
              <datalist id="categoryList">
                {existingCategories.map((cat, index) => (
                  <option key={index} value={cat} />
                ))}
              </datalist>
            </div>
            <div className="form-group">
              <label htmlFor="tileCategory">Tile Category</label>
              <input 
                list="tileCategoryList" 
                type="text" 
                className="form-control" 
                id="tileCategory" 
                placeholder="Enter or select a tile category" 
                value={tileCategory} 
                onChange={(e) => setTileCategory(e.target.value)} 
                required 
              />
              <datalist id="tileCategoryList">
                {existingTileCategories.map((tileCat, index) => (
                  <option key={index} value={tileCat} />
                ))}
              </datalist>
            </div>
            <div className="form-group">
              <label htmlFor="level">Lesson Level</label> {/* New dropdown for selecting lesson level */}
              <select className="form-control" id="level" value={level} onChange={(e) => setLevel(e.target.value)} required>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Advanced">Advanced</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="badgeName">Badge Name</label>
              <input type="text" className="form-control" id="badgeName" placeholder="Enter badge name" value={badgeName} onChange={(e) => setBadgeName(e.target.value)} required />
            </div>
            <div className="form-group">
              <label htmlFor="badgeUrl">Badge Image URL</label>
              <input type="text" className="form-control" id="badgeUrl" placeholder="Enter badge image URL" value={badgeUrl} onChange={(e) => setBadgeUrl(e.target.value)} required />
            </div>
            <div className="form-group">
              <label htmlFor="badgeSummary">Badge Summary</label> 
              <textarea className="form-control" id="badgeSummary" rows="3" placeholder="Enter summary of what the user has learned" value={badgeSummary} onChange={(e) => setBadgeSummary(e.target.value)} required />
            </div>
            <div className="form-group">
              <label htmlFor="audience">Audience</label> 
              <select className="form-control" id="audience" value={audience} onChange={(e) => setAudience(e.target.value)} required>
                <option value="Both">Both</option>
                <option value="Pupil">Pupil</option>
                <option value="Staff">Staff</option>
              </select>
            </div>
            <hr />
            <h4 onClick={handleToggleLessonContent} style={{ cursor: 'pointer' }}>Lesson Content {showLessonContent ? '▲' : '▼'}</h4>
            {showLessonContent && steps.map((step, index) => (
              <div key={index} className="card mb-2">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor={`step-title-${index}`}>Lesson Title</label>
                    <input type="text" className="form-control" id={`step-title-${index}`} name="title" placeholder="Enter lesson title" value={step.title} onChange={(e) => handleStepChange(index, e)} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`step-content-${index}`}>Lesson Description</label>
                    <textarea className="form-control" id={`step-content-${index}`} name="content" rows="3" placeholder="Enter lesson description" value={step.content} onChange={(e) => handleStepChange(index, e)} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`step-mediaUrl-${index}`}>Media URL (YouTube or Image)</label>
                    <input type="text" className="form-control" id={`step-mediaUrl-${index}`} name="mediaUrl" placeholder="Enter media URL" value={step.mediaUrl} onChange={(e) => handleStepChange(index, e)} />
                  </div>
                  <div className="text-end">
                    <button type="button" className="btn btn-danger" onClick={() => handleRemoveStep(index)}>Remove Lesson Content</button>
                  </div>
                </div>
              </div>
            ))}
            <div className="text-center">
              <button type="button" className="btn btn-secondary mb-4" onClick={handleAddStep}>Add Lesson Content</button>
            </div>
            <hr />
            <h4 onClick={handleToggleQuiz} style={{ cursor: 'pointer' }}>Quiz {showQuiz ? '▲' : '▼'}</h4>
            {showQuiz && quiz.map((question, questionIndex) => (
              <div key={questionIndex} className="card mb-2">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor={`question-${questionIndex}`}>Question</label>
                    <input type="text" className="form-control" id={`question-${questionIndex}`} name="question" placeholder="Enter quiz question" value={question.question} onChange={(e) => handleQuizChange(questionIndex, e)} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`type-${questionIndex}`}>Question Type</label>
                    <select className="form-control" id={`type-${questionIndex}`} name="type" value={question.type} onChange={(e) => handleQuizChange(questionIndex, e)} required>
                      <option value="">Select type</option>
                      <option value="single">Single Correct Answer</option>
                      <option value="multiple">Multiple Correct Answers</option>
                    </select>
                  </div>
                  {question.options.map((option, optionIndex) => (
                    <div key={optionIndex} className="form-group">
                      <label htmlFor={`option-${questionIndex}-${optionIndex}`}>Option {optionIndex + 1}</label>
                      <input type="text" className="form-control" id={`option-${questionIndex}-${optionIndex}`} placeholder={`Enter option ${optionIndex + 1}`} value={option} onChange={(e) => handleOptionChange(questionIndex, optionIndex, e)} required />
                      <div className="form-check">
                        <input type="radio" className="form-check-input" id={`correctAnswer-${questionIndex}-${optionIndex}`} name={`correctAnswer-${questionIndex}`} checked={question.correctAnswerIndex === optionIndex} onChange={() => handleCorrectAnswerChange(questionIndex, optionIndex)} />
                        <label className="form-check-label" htmlFor={`correctAnswer-${questionIndex}-${optionIndex}`}>Correct Answer</label>
                      </div>
                      {question.options.length > 1 && (
                        <button type="button" className="btn btn-danger mt-2" onClick={() => handleRemoveOption(questionIndex, optionIndex)}>Remove Option</button>
                      )}
                    </div>
                  ))}
                  <button type="button" className="btn btn-secondary mb-2" onClick={() => handleAddOption(questionIndex)}>Add Option</button>
                  <div className="text-end">
                    <button type="button" className="btn btn-danger" onClick={() => handleRemoveQuestion(questionIndex)}>Remove Question</button>
                  </div>
                </div>
              </div>
            ))}
            <div className="text-center">
              <button type="button" className="btn btn-secondary mb-4" onClick={handleAddQuestion}>Add Question</button>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">Create Lesson</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LessonCreator;
