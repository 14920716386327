import { useEffect, useState, useCallback } from 'react';
import { firestore } from '../firebaseConfig';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import styles from './MainPage.module.css';

const MainPage = ({ user }) => {
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reviewNotifications, setReviewNotifications] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLessons, setFilteredLessons] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [badges, setBadges] = useState([]);
  const [tiles, setTiles] = useState({});
  const [activeCategories, setActiveCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categorySummary, setCategorySummary] = useState('');
  const [bookmarkedLessons, setBookmarkedLessons] = useState([]);

  const navigate = useNavigate();

  const fetchCategorySummary = useCallback(async (category) => {
    try {
      const categoryDocRef = doc(firestore, 'categorySummary', category);
      const categoryDoc = await getDoc(categoryDocRef);
      if (categoryDoc.exists()) {
        setCategorySummary(categoryDoc.data().summary);
      } else {
        setCategorySummary('No summary available for this category.');
      }
    } catch (error) {
      console.error('Error fetching category summary:', error);
      setCategorySummary('Failed to load summary.');
    }
  }, []);

  const fetchCompletedLessons = useCallback(async () => {
    try {
      const completedLessonsCollection = collection(
        firestore,
        'users',
        user.uid,
        'completedLessons'
      );
      const completedLessonsSnapshot = await getDocs(completedLessonsCollection);

      const completedLessonsData = await Promise.all(
        completedLessonsSnapshot.docs.map(async (docSnapshot) => {
          const lessonDoc = await getDoc(doc(firestore, 'lessons', docSnapshot.id));
          if (lessonDoc.exists()) {
            const lessonData = lessonDoc.data();
            return {
              ...docSnapshot.data(),
              lessonId: docSnapshot.id,
              title: lessonData.title,
              category: lessonData.category,
            };
          } else {
            console.error(`No such document for lesson ${docSnapshot.id}`);
            return null;
          }
        })
      );

      const validCompletedLessonsData = completedLessonsData.filter(
        (lesson) => lesson !== null
      );
      const notifications = validCompletedLessonsData.filter((lesson) =>
        isOverOneYearOld(lesson.completionDate)
      );
      setReviewNotifications(notifications);
    } catch (error) {
      console.error('Error fetching completed lessons:', error);
    }
  }, [user.uid]);

  const fetchBadges = useCallback(async () => {
    try {
      const badgesCollection = collection(
        firestore,
        'users',
        user.uid,
        'awardedBadges'
      );
      const badgesSnapshot = await getDocs(badgesCollection);
      const badgesData = badgesSnapshot.docs
        .map((doc) => doc.data())
        .sort((a, b) => a.name.localeCompare(b.name));
      setBadges(badgesData);
    } catch (error) {
      console.error('Error fetching badges:', error);
    }
  }, [user.uid]);

  const fetchLessons = async () => {
    try {
      const lessonsCollection = collection(firestore, 'lessons');
      const lessonsSnapshot = await getDocs(lessonsCollection);
      const lessonsData = lessonsSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((lesson) => {
          const { audience, status } = lesson;
          if (status !== 'approved' && status !== undefined) return false;
          if (user.role === 'Pupil' && audience === 'Staff') return false;
          if (user.role === 'Staff' && audience === 'Pupil') return false;
          return true;
        })
        .sort((a, b) => {
          const levelOrder = { Beginner: 1, Intermediate: 2, Advanced: 3 };
  
          const levelA = a.level || 'Advanced'; // Default to Advanced if missing
          const levelB = b.level || 'Advanced'; // Default to Advanced if missing
  
          return levelOrder[levelA] - levelOrder[levelB];
        });
  
      // Generate tile categories based on the lessons data
      const tileData = {};
      lessonsData.forEach((lesson) => {
        const { tileCategory, category } = lesson;
        if (tileCategory) {
          if (!tileData[tileCategory]) {
            tileData[tileCategory] = [];
          }
          if (!tileData[tileCategory].includes(category)) {
            tileData[tileCategory].push(category);
          }
        }
      });
  
      // Now we can use both setLessons and setTiles
      setLessons(lessonsData);
      setFilteredLessons(lessonsData);
      setTiles(tileData); // Update the tiles state with categories
      setLoading(false);
    } catch (error) {
      console.error('Error fetching lessons:', error);
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchLessons();
    fetchCompletedLessons();
    fetchBadges();
  }, [fetchCompletedLessons, fetchBadges]);

  useEffect(() => {
    const lessonsCollection = collection(firestore, 'lessons');
    const unsubscribe = onSnapshot(lessonsCollection, (snapshot) => {
      const newNotifications = [];
      snapshot.docChanges().forEach((change) => {
        const lessonData = { ...change.doc.data(), id: change.doc.id };
        const currentTime = new Date();
        const createdAt = lessonData.createdAt?.toDate() || currentTime;
        const updatedAt = lessonData.updatedAt?.toDate() || currentTime;
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

        if (
          (change.type === 'added' && createdAt > twoDaysAgo) ||
          (change.type === 'modified' && updatedAt > twoDaysAgo)
        ) {
          newNotifications.push({ type: change.type, ...lessonData });
        }
      });

      setNotifications((prevNotifications) => {
        const uniqueNotifications = new Map();
        [...prevNotifications, ...newNotifications].forEach((notification) => {
          uniqueNotifications.set(notification.id, notification);
        });
        return Array.from(uniqueNotifications.values());
      });
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setFilteredLessons(
        lessons.filter(
          (lesson) =>
            lesson.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            lesson.category.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredLessons(lessons);
    }
  }, [searchTerm, lessons]);

  const isOverOneYearOld = (completionDate) => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    return new Date(completionDate) < oneYearAgo;
  };

  const handleLessonClick = (lessonId) => {
    navigate(`/lesson/${lessonId}`);
  };

  const handleCreateLessonClick = () => {
    navigate('/create-lesson');
  };

  const handleEditLessonClick = (lessonId) => {
    navigate(`/edit-lesson/${lessonId}`);
  };

  const handleDeleteLesson = async (lessonId) => {
    try {
      await deleteDoc(doc(firestore, 'lessons', lessonId));
      setLessons(lessons.filter((lesson) => lesson.id !== lessonId));
    } catch (error) {
      console.error('Error deleting lesson:', error);
    }
  };

  const groupByCategory = (lessons) => {
    return lessons.reduce((acc, lesson) => {
      if (!acc[lesson.category]) {
        acc[lesson.category] = [];
      }
      acc[lesson.category].push(lesson);
      return acc;
    }, {});
  };

  const isAdminOrHeadTeacher = () => {
    if (user.role === 'admin') return true;
    const headTeacherRoles = [
      'headAberlady',
      'headAthelstaneford',
      'headCampie',
      'headCockenzie',
      'headDirleton',
      'headDunbarGrammar',
      'headDunbarPrimary',
      'headEastLinton',
      'headElphinstone',
      'headGullane',
      'headHaddington',
      'headInnerwick',
      'headKnox',
      'headLaw',
      'headLethamMains',
      'headLongniddry',
      'headLoretto',
      'headMacmerry',
      'headMusselburghBurgh',
      'headMusselburghGrammar',
      'headNorthBerwick',
      'headOrmiston',
      'headPencaitland',
      'headPinkie',
      'headPrestonLodge',
      'headPrestonTower',
      'headRosehill',
      'headRoss',
      'headSaltoun',
      'headSandersonsWynd',
      'headStGabriels',
      'headStMartins',
      'headStMarys',
      'headStenton',
      'headStoneyhill',
      'headWallyford',
      'headWestBarns',
      'headWhitecraig',
      'headWindygoul',
      'headYester',
      'headJMH',
    ];
    return headTeacherRoles.includes(user.role);
  };

  const handleTileClick = (categories) => {
    setActiveCategories(categories);
    setSelectedCategory(categories[0]);
    fetchCategorySummary(categories[0]);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    fetchCategorySummary(category);
  };

  const toggleBookmark = async (lessonId) => {
    const userDocRef = doc(
      firestore,
      'users',
      user.uid,
      'bookmarkedLessons',
      lessonId
    );
    const bookmarkedLesson = bookmarkedLessons.includes(lessonId);

    if (bookmarkedLesson) {
      await deleteDoc(userDocRef);
      setBookmarkedLessons(bookmarkedLessons.filter((id) => id !== lessonId));
    } else {
      await setDoc(userDocRef, { lessonId });
      setBookmarkedLessons([...bookmarkedLessons, lessonId]);
    }
  };

  const handleAdminDashboardClick = () => {
    navigate('/admin-dashboard');
  };

  useEffect(() => {
    const fetchBookmarkedLessons = async () => {
      try {
        const bookmarkedCollection = collection(
          firestore,
          'users',
          user.uid,
          'bookmarkedLessons'
        );
        const bookmarkedSnapshot = await getDocs(bookmarkedCollection);
        const bookmarkedLessonIds = bookmarkedSnapshot.docs.map((doc) => doc.id);
        setBookmarkedLessons(bookmarkedLessonIds);
      } catch (error) {
        console.error('Error fetching bookmarked lessons:', error);
      }
    };

    fetchBookmarkedLessons();
  }, [user.uid]);

  if (loading) {
    return <p>Loading lessons...</p>;
  }

  const groupedLessons = groupByCategory(filteredLessons);

  return (
    <div className={styles.mainPageContainer}>
      <div className={styles.container}>
        <div className={styles.badgesContainer}>
          <h4>Earned Badges</h4>
          {badges.length > 0 ? (
            <ul className="list-group">
              {badges.map((badge, index) => (
                <li
                  key={index}
                  className={`list-group-item d-flex align-items-center ${styles.badgeItem}`}
                >
                  <img
                    src={badge.iconUrl}
                    alt={badge.name}
                    className={styles.badgeIcon}
                  />
                  <span className={styles.badgeText}>{badge.name}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No badges earned yet</p>
          )}
        </div>
        <div className={styles.mainContent}>
          <div className={styles.mainContentCard}>
            <div className={`d-flex align-items-center ${styles.cardHeader}`}>
              <img
                src="https://lh3.googleusercontent.com/d/1XnKCVqzUOoiSecOcfMItGygphfcfHtB6"
                alt="Logo"
                className={styles.headerLogo}
              />
              <h1 className="ms-3">Learning Library</h1>
              <span className={styles.welcomeMessage}>
                Welcome, {user.displayName}
              </span>
            </div>
            <div className={styles.cardBody}>
              <div className="d-flex justify-content-between align-items-center mb-3">
                {user && user.role === 'admin' && (
                  <button
                    className={styles.customButton}
                    onClick={handleCreateLessonClick}
                  >
                    Create New Lesson
                  </button>
                )}
                <div className="d-flex">
                  {user && user.role !== 'Pupil' && (
                    <Link to="/calendar" className={styles.customButton}>
                      View CLPL Calendar
                    </Link>
                  )}
                  {user && isAdminOrHeadTeacher() && (
                    <Link
                      to="/review-all-progress"
                      className={styles.customButton}
                    >
                      Review All User Progress
                    </Link>
                  )}
                  <Link to="/progress" className={styles.customButton}>
                    My Profile
                  </Link>
                  {user && user.role === 'admin' && (
                    <button
                      className={styles.customButton}
                      onClick={handleAdminDashboardClick}
                    >
                      Admin Dashboard
                    </button>
                  )}
                </div>
              </div>
              <div className={styles.formGroup}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search lessons..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className={styles.tileContainer}>
                {Object.keys(tiles).map((tile) => (
                  <div
                    key={tile}
                    className={styles.tile}
                    onClick={() => handleTileClick(tiles[tile])}
                  >
                    {tile}
                  </div>
                ))}
              </div>
              {activeCategories.length > 0 && (
                <>
                  <h2 className={styles.availableLearningHeading}>
                    Available Learning
                  </h2>
                  <div className="tabs">
                    <ul className="nav nav-tabs">
                      {activeCategories.map((category) => (
                        <li
                          className="nav-item"
                          key={category}
                          style={{ marginRight: '10px' }}
                        >
                          <button
                            className="nav-link"
                            onClick={() => handleCategoryClick(category)}
                            style={{
                              padding: '10px 20px',
                              backgroundColor:
                                category === selectedCategory
                                  ? '#0056b3'
                                  : '#004a99',
                              color: 'white',
                              borderRadius: '8px 8px 0 0',
                              position: 'relative',
                              transition:
                                'background-color 0.3s ease, box-shadow 0.3s ease',
                              border: 'none',
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              boxShadow:
                                category === selectedCategory
                                  ? '0 2px 5px rgba(0, 0, 0, 0.2)'
                                  : 'none',
                              fontWeight:
                                category === selectedCategory
                                  ? 'bold'
                                  : 'normal',
                              minWidth: '120px',
                              overflow: 'visible', // Ensure notch is visible
                              zIndex: category === selectedCategory ? '2' : '1',
                            }}
                          >
                            <span>{category}</span>
                            {/* Folder tab notch effect */}
                            <span
                              style={{
                                position: 'absolute',
                                top: '-10px',
                                left: '0',
                                width: '40px',
                                height: '20px',
                                backgroundColor:
                                  category === selectedCategory
                                    ? '#0056b3'
                                    : '#004a99',
                                borderRadius: '8px 8px 0 0',
                              }}
                            ></span>
                          </button>
                        </li>
                      ))}
                    </ul>
                    <div className="tab-content">
                      {selectedCategory && (
                        <div className={styles.categorySummary}>
                          <p>{categorySummary}</p>
                        </div>
                      )}
                      {activeCategories.map((category) => (
                        <div
                          key={category}
                          className={`tab-pane fade ${
                            category === selectedCategory ? 'show active' : ''
                          }`}
                        >
                          <ul className="list-group">
                            {groupedLessons[category] &&
                              groupedLessons[category].map((lesson) => (
                                <li
                                  key={lesson.id}
                                  className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${
                                    bookmarkedLessons.includes(lesson.id)
                                      ? styles.bookmarked
                                      : ''
                                  }`}
                                >
                                  <span
                                    className="lesson-title"
                                    onClick={() => handleLessonClick(lesson.id)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {lesson.title}
                                  </span>
                                  <div>
                                    <button
                                      className={styles.bookmarkButton}
                                      onClick={() => toggleBookmark(lesson.id)}
                                      aria-label={`Bookmark ${lesson.title}`}
                                    >
                                      {bookmarkedLessons.includes(lesson.id) ? (
                                        <FaBookmark />
                                      ) : (
                                        <FaRegBookmark />
                                      )}
                                    </button>
                                    {user && user.role === 'admin' && (
                                      <>
                                        <button
                                          className={styles.customButton}
                                          onClick={() =>
                                            handleEditLessonClick(lesson.id)
                                          }
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className={styles.customButton}
                                          onClick={() =>
                                            handleDeleteLesson(lesson.id)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.notificationsContainer}>
          <h4>Notifications</h4>
          {notifications.length > 0 ? (
            <ul className="list-group">
              {notifications.map((notification, index) => (
                <li key={index} className="list-group-item">
                  {notification.type === 'added' &&
                    `New lesson added: ${notification.title}`}
                  {notification.type === 'modified' &&
                    `Lesson updated: ${notification.title}`}
                  {notification.type === 'removed' &&
                    `Lesson removed: ${notification.title}`}
                </li>
              ))}
            </ul>
          ) : (
            <p>No new notifications</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
