import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { firestore } from '../firebaseConfig';
import EventComponent from './EventComponent';
import styles from './CLPLCalendar.module.css';
import { gapi } from 'gapi-script';
import { collection, addDoc, onSnapshot } from 'firebase/firestore';

const localizer = momentLocalizer(moment);

const CLPLCalendar = ({ user }) => {
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [newResourceName, setNewResourceName] = useState('');
  const [newResourceURL, setNewResourceURL] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventsFromGoogleCalendar = async () => {
      try {
        gapi.load('client:auth2', () => {
          gapi.client.init({
            apiKey: 'AIzaSyDaCSHsOz7oOz6dXRz3sCE8fz1CRMqt3lU',
            clientId: '510467765457-ska9npciihhnincgt80l07188chjet28.apps.googleusercontent.com',
            discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
            scope: 'https://www.googleapis.com/auth/calendar.readonly',
          }).then(() => {
            gapi.client.calendar.events.list({
              calendarId: 'clpl@edubuzz.org',
              timeMin: (new Date()).toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 50,
              orderBy: 'startTime'
            }).then(response => {
              const eventsData = response.result.items.map(event => ({
                id: event.id,
                title: event.summary,
                start: new Date(event.start.dateTime || event.start.date),
                end: new Date(event.end.dateTime || event.end.date),
                location: event.location || '',
                presenter: event.organizer?.displayName || 'N/A',
              }));
              setEvents(eventsData);
            });
          });
        });
      } catch (error) {
        console.error('Error fetching events from Google Calendar:', error);
      }
    };

    fetchEventsFromGoogleCalendar();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestore, 'resources'), (snapshot) => {
      const resourcesData = snapshot.docs.map(doc => doc.data());
      setResources(resourcesData);
    });

    return () => unsubscribe();
  }, []);

  const handleAddResource = async () => {
    if (newResourceName && newResourceURL) {
      try {
        await addDoc(collection(firestore, 'resources'), { name: newResourceName, url: newResourceURL });
        setNewResourceName('');
        setNewResourceURL('');
      } catch (error) {
        console.error('Error adding resource:', error);
      }
    }
  };

  return (
    <div className={styles.clplCalendarContainer}>
      <div className={styles.mainContent}>
        <div className={styles.card}>
          <div className={styles.cardHeader}>
            <h1>CLPL Calendar</h1>
            <button className={`${styles.btn} ${styles.btnAnimated} ${styles.btnSecondary}`} onClick={() => navigate('/')}>
              Back to Lessons
            </button>
          </div>
          <div className={styles.cardBody}>
            <div className={styles.calendarContainer}>
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 'calc(100vh - 200px)' }}
                components={{
                  event: EventComponent
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.resourcesContainer}>
        <div className={styles.resourcesHeader}>
          <h4>Resources</h4>
        </div>
        <ul>
          {resources.map((resource, index) => (
            <li key={index}>
              <a href={resource.url} target="_blank" rel="noopener noreferrer">{resource.name}</a>
            </li>
          ))}
        </ul>
        {user && user.role === 'admin' && (
          <div className="mt-4">
            <h4>Add New Resource</h4>
            <div className="form-group">
              <label htmlFor="newResourceName">Resource Name</label>
              <input
                type="text"
                className="form-control"
                id="newResourceName"
                placeholder="Enter resource name"
                value={newResourceName}
                onChange={(e) => setNewResourceName(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="newResourceURL">Resource URL</label>
              <input
                type="text"
                className="form-control"
                id="newResourceURL"
                placeholder="Enter resource URL"
                value={newResourceURL}
                onChange={(e) => setNewResourceURL(e.target.value)}
              />
            </div>
            <button className={`${styles.btn} ${styles.btnAnimated} ${styles.btnPrimary} mt-3`} onClick={handleAddResource}>
              Add Resource
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CLPLCalendar;
