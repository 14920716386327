import React, { useEffect, useState } from 'react';
import { firestore } from '../firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import styles from './NewQuizPage.module.css';
import { useNavigate } from 'react-router-dom';

const NewQuizPage = ({ lessonId }) => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [lessonData, setLessonData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const quizCollection = collection(firestore, 'lessons', lessonId, 'quiz');
        const quizSnapshot = await getDocs(quizCollection);
        const quizData = quizSnapshot.docs.map(doc => ({
          id: doc.id,
          lessonId,
          ...doc.data()
        }));
        setQuestions(quizData);

        const lessonDocRef = doc(firestore, 'lessons', lessonId);
        const lessonDoc = await getDoc(lessonDocRef);
        if (lessonDoc.exists()) {
          setLessonData(lessonDoc.data());
        } else {
          console.error('No such document for lesson:', lessonId);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        setLoading(false);
      }
    };

    fetchQuizData();
  }, [lessonId]);

  const handleCheckboxChange = (questionId, option) => {
    setAnswers(prevAnswers => {
      const currentAnswers = prevAnswers[questionId] || [];
      if (currentAnswers.includes(option)) {
        return {
          ...prevAnswers,
          [questionId]: currentAnswers.filter(answer => answer !== option)
        };
      } else {
        return {
          ...prevAnswers,
          [questionId]: [...currentAnswers, option]
        };
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { title, category } = lessonData;
    navigate('/quiz-results', { state: { questions, answers, lessonId, title, category } });
  };

  if (loading) {
    return <p>Loading quiz...</p>;
  }

  return (
    <div className={styles.quizContainer}>
      <div className={styles.quizCard}>
        <h2>Quiz</h2>
        <form onSubmit={handleSubmit}>
          {questions.map((question) => (
            <div key={question.id} className={styles.formGroup}>
              <label className={styles.question}>{question.question}</label>
              {Array.isArray(question.options) ? (
                question.options.map((option) => (
                  <div key={option} className={styles.formCheck}>
                    <input
                      type="checkbox"
                      id={`${question.id}-${option}`}
                      name={`${question.id}-${option}`}
                      value={option}
                      className={styles.formCheckInput}
                      checked={answers[question.id] ? answers[question.id].includes(option) : false}
                      onChange={() => handleCheckboxChange(question.id, option)}
                    />
                    <label className={styles.formCheckLabel} htmlFor={`${question.id}-${option}`}>
                      {option}
                    </label>
                  </div>
                ))
              ) : (
                <p>Error: Options are not an array</p>
              )}
            </div>
          ))}
          <button type="submit" className={styles.btnPrimary}>
            Submit Quiz
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewQuizPage;
