import React, { useState, useEffect } from 'react';
import { auth, provider } from '../firebaseConfig';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import styles from './Auth.module.css';

const Auth = ({ onUserChange }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setLoading(true);
      if (user) {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          user = { ...user, role: userData.role, displayName: userData.displayName, school: userData.school };
          if (!userData.school || !userData.role) {
            navigate('/select-school');
          }
        } else {
          await setDoc(userDocRef, {
            displayName: user.displayName,
            email: user.email,
            role: 'user',
            school: ''
          });
          navigate('/select-school');
        }
      }
      setUser(user);
      onUserChange(user);
      setLoading(false);
      if (user && user.school && user.role) {
        setTimeout(() => navigate('/'), 0);
      }
    });

    return () => unsubscribe();
  }, [onUserChange, navigate]);

  useEffect(() => {
    // Fetch the YouTube video URL from Firestore
    const fetchVideoUrl = async () => {
      try {
        const videoDocRef = doc(firestore, 'loginPage', 'video');
        const videoDoc = await getDoc(videoDocRef);
        if (videoDoc.exists()) {
          const videoData = videoDoc.data();
          const urlWithControls = `${videoData.mediaUrl}?controls=0`; // Disable all controls except play/pause
          setVideoUrl(urlWithControls);
        }
      } catch (error) {
        console.error('Error fetching video URL:', error);
      }
    };

    fetchVideoUrl();
  }, []);

  const handleLogin = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className={styles.authContainer}>
      <div className={styles.authSidebar}>
        <div className={styles.authContent}>
          <img
            src="https://live.staticflickr.com/65535/53861635539_e6e189f086_o.png"
            alt="Logo"
            className={styles.authLogo}
          />
          <h1 className={styles.authTitle}>Learning Library</h1>
          {loading ? (
            <p>Loading...</p>
          ) : user ? (
            <div className={styles.authInfo}>
              <span className={styles.authWelcome}>Welcome, {user.displayName}</span>
            </div>
          ) : (
            <img
              src="https://lh6.googleusercontent.com/d/1I8pc1jLh3s56AonyV90e0AvpTmtMlKne"
              alt="Google Login"
              className={styles.googleButton}
              onClick={handleLogin}
            />
          )}
        </div>
        <footer className={styles.authFooter}>
          Created by Education and the Digital Learning Team
        </footer>
      </div>

      {/* Video Section */}
      {videoUrl && (
        <div className={styles.videoWrapper}>
          <iframe
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Login Page Video"
          ></iframe>
        </div>
      )}

      {/* Key Features Section */}
      <div className={styles.featuresSection}>
        <div className={styles.featureBox}>
          <i className="fas fa-book-open"></i>
          <h3>Wide Range of Lessons</h3>
          <p>Access a variety of topics and lessons to expand your knowledge.</p>
        </div>
        <div className={styles.featureBox}>
          <i className="fas fa-chart-line"></i>
          <h3>Track Your Progress</h3>
          <p>Monitor your achievements and milestones as you learn.</p>
        </div>
        <div className={styles.featureBox}>
          <i className="fas fa-users"></i>
          <h3>Learn with Community</h3>
          <p>Join a growing community of learners and educators.</p>
        </div>
        <div className={styles.featureBox}>
          <i className="fas fa-graduation-cap"></i>
          <h3>Earn Badges</h3>
          <p>Get rewarded with badges as you complete courses and lessons.</p>
        </div>
      </div>
    </div>
  );
};

export default Auth;
