import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import styles from './TutorialStep.module.css';

const TutorialStep = ({ step, nextStep, prevStep, isLastStep, userId }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoCompleted, setVideoCompleted] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [isAdmin, setIsAdmin] = useState(false);
  const playerRef = useRef(null);

  useEffect(() => {
    const checkUserRole = async () => {
      if (!userId) {
        console.log("No userId provided");
        return;
      }

      console.log("Checking user role for userId:", userId);

      const db = getFirestore();
      const userRef = doc(db, 'users', userId);
      
      try {
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          console.log("User data:", userData);
          const userIsAdmin = userData.role === 'admin';
          console.log("Is user admin?", userIsAdmin);
          setIsAdmin(userIsAdmin);
        } else {
          console.log("No user document found for userId:", userId);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    checkUserRole();
  }, [userId]);

  const handleImageClick = () => {
    setIsPlaying(!isPlaying);
  };

  const isYouTubeLink = (url) => {
    return url && (url.includes('youtube.com') || url.includes('youtu.be'));
  };

  const getYouTubeEmbedUrl = (url) => {
    let videoId = '';
    if (url.includes('youtube.com')) {
      const urlParams = new URLSearchParams(new URL(url).search);
      videoId = urlParams.get('v');
    } else if (url.includes('youtu.be')) {
      videoId = url.split('/').pop();
    }
    return videoId;
  };

  const onPlayerStateChange = useCallback((event) => {
    if (event.data === window.YT.PlayerState.ENDED) {
      setVideoCompleted(true);
    } else if (event.data === window.YT.PlayerState.PLAYING) {
      setVideoStarted(true);
      setVideoCompleted(false);
    } else if (event.data === window.YT.PlayerState.PAUSED && videoStarted) {
      if (typeof event.target.playVideo === 'function') {
        event.target.playVideo();
      }
    }
  }, [videoStarted]);

  const onPlayerReady = (event) => {
    playerRef.current = event.target;
    event.target.setPlaybackQuality('highres');
  };

  useEffect(() => {
    const loadYouTubePlayer = () => {
      new window.YT.Player(`youtube-player-${step.id}`, {
        videoId: getYouTubeEmbedUrl(step.mediaUrl),
        events: {
          onStateChange: onPlayerStateChange,
          onReady: onPlayerReady,
        },
        playerVars: {
          controls: 0,
          disablekb: 1,
          modestbranding: 1,
          rel: 0,
          fs: 0,
        },
      });
    };

    if (isYouTubeLink(step.mediaUrl)) {
      if (window.YT && window.YT.Player) {
        loadYouTubePlayer();
      } else {
        window.onYouTubeIframeAPIReady = loadYouTubePlayer;
      }
    }
  }, [step.id, step.mediaUrl, onPlayerStateChange]);

  const handleSpeedChange = (event) => {
    const newSpeed = parseFloat(event.target.value);
    setPlaybackSpeed(newSpeed);
    if (playerRef.current && typeof playerRef.current.setPlaybackRate === 'function') {
      playerRef.current.setPlaybackRate(newSpeed);
    }
  };

  const handleSkip = () => {
    setVideoCompleted(true);
    if (playerRef.current && typeof playerRef.current.stopVideo === 'function') {
      playerRef.current.stopVideo();
    }
  };

  console.log("Render - isAdmin:", isAdmin);
  console.log("Render - isYouTubeLink:", isYouTubeLink(step.mediaUrl));

  return (
    <div className={styles.tutorialStepContainer}>
      <div className={`card ${styles.tutorialStepCard} mb-4`}>
        <div className={`card-header d-flex justify-content-between align-items-center ${styles.cardHeader}`}>
          <span>{step.title}</span>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => window.speechSynthesis.speak(new SpeechSynthesisUtterance(step.content))}
          >
            Listen
          </button>
        </div>
        <div className="card-body">
          <p className="card-text">{step.content}</p>
          {step.mediaUrl && isYouTubeLink(step.mediaUrl) ? (
            <div className={styles.videoContainer}>
              <div id={`youtube-player-${step.id}`} className={styles.videoFrame}></div>
            </div>
          ) : (
            <div className={styles.gifContainer} onClick={handleImageClick}>
              <img
                src={isPlaying ? step.gifAnimated : step.gifStatic}
                alt={step.title}
                className="img-fluid rounded"
              />
              {!isPlaying && step.gifAnimated && (
                <div className={styles.playButton}>
                  <i className="fas fa-play-circle"></i>
                </div>
              )}
            </div>
          )}
          <div className="mt-3 d-flex justify-content-between align-items-center">
            <div>
              {prevStep && <button className="btn btn-outline-secondary me-2" onClick={prevStep}>Previous</button>}
              <button className="btn btn-outline-primary" onClick={nextStep} disabled={!videoCompleted}>
                {isLastStep ? 'Continue' : 'Next'}
              </button>
              {isAdmin && isYouTubeLink(step.mediaUrl) && (
                <button className="btn btn-warning ms-2" onClick={handleSkip}>
                  Skip Video (Admin Only)
                </button>
              )}
            </div>
            {step.mediaUrl && isYouTubeLink(step.mediaUrl) && (
              <div className={styles.playbackSpeedControl}>
                <label htmlFor="speed-select">Speed:</label>
                <select
                  id="speed-select"
                  value={playbackSpeed}
                  onChange={handleSpeedChange}
                >
                  <option value="0.25">0.25x</option>
                  <option value="0.5">0.5x</option>
                  <option value="0.75">0.75x</option>
                  <option value="1">Normal</option>
                  <option value="1.25">1.25x</option>
                  <option value="1.5">1.5x</option>
                  <option value="2">2x</option>
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorialStep;